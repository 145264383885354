import Lenis from '@studio-freight/lenis'
import gsap from "gsap";

// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)
const lenis = new Lenis({
  duration: 0,
})
lenis.on('scroll', (e) => {})
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
const anchors = document.querySelectorAll(".js-anchor");
const header = document.querySelector('.header');
anchors.forEach((anchor) => {
  anchor.addEventListener("click", (e) => {
    e.preventDefault();
    const to = e.currentTarget.getAttribute('href')
    lenis.scrollTo( document.querySelector(to).offsetTop - header.offsetHeight - 40);
  });
})
const smartBreakPoint = 769;
const smartBreakPointMediaQuery = "(min-width: "+smartBreakPoint+"px)";
const isSmartPhone = () => {
  // デバイス幅が640px以下の場合にスマホと判定する
  if (window.matchMedia && window.matchMedia('(max-device-width: '+(smartBreakPoint-1)+'px)').matches) {
    return true;
  } else {
    return false;
  }
}



let mm = gsap.matchMedia();
const initParallaxCard = () => {
  const card = gsap.utils.toArray('.js-parallax-card');
  const wrapper = document.querySelector('.js-parallax-cards');
  if (card.length === 0 ) {
    return false;
  }
  mm.add(smartBreakPointMediaQuery, () => {
    gsap.set(card, { y: 300 });
    const parallaxTl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          // start: "top-=100 top",
          start: "top top",
          // end: "bottom top",
          end: wrapper.offsetHeight * 4,
          // markers: true,
          pin: true,
          scrub: 2.5,
          duration: 3,
          anticipatePin: 1,
          // invalidateOnRefresh: true,
          // animation: pinScroll,
        },
    }); 
    parallaxTl.to(card, { y:0, duration: 2, stagger: 1,delay: .5,})
    parallaxTl.to(card, { delay: 1.5,rotateY: 180, duration: 4.5, stagger: 2})
  });
}
// initParallaxCard()
/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes]; // ノードリストを配列にする
  let returnText = ''; // 最終的に返すテキスト

  for (const node of nodes) {
    if (node.nodeType == 3) {
      //テキストの場合
      const text = node.textContent.replace(/\r?\n/g, ''); //テキストから改行コード削除
      const splitText = text.split(''); // 一文字ずつ分割
      for (const char of splitText) {
        returnText += `<span>${char}</span>`; // spanタグで挟んで連結
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

const spanWrapTextElements = document.querySelectorAll('.js-spanwrap')
if (spanWrapTextElements) {
  // console.log(spanWrapTextElements)
  spanWrapTextElements.forEach((spanWrapTextElement) => {
    spanWrapTextElement.innerHTML = spanWrapText(spanWrapTextElement)
  });
}


let featureTitle = document.querySelector('.js-feature-title')
if (featureTitle) {
  featureTitle.innerHTML = spanWrapText(featureTitle)
}
gsap.utils.toArray('.js-enter-active').forEach((box, index) => {
  gsap.to(box, {
    scrollTrigger: {
      trigger: box,
      start: 'top bottom',
      onEnter: () => box.classList.add('is-active'),
    }
  });
});
// gsap.fromTo(featureTitle, {
//   y: 20,
//   scale: 0
// }, {
//   y:0 ,
//   scale: 1,
//   duration: 1,
//   scrollTrigger: {
//     trigger: box,
//     start: 'top bottom',
//     end: 'bottom top',
//   }
// });

const scalein = gsap.utils.toArray('.js-scalein-effect1');
if (scalein.length != 0) {
  scalein.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      scale: 0
    }, {
      y:0 ,
      scale: 1,
      duration: 1,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}

const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 2,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const stfadeInEls = gsap.utils.toArray('.js-stagger-fadein-effect1');
if (stfadeInEls.length != 0) {
  // stfadeInEls.forEach((box, index) => {
    gsap.fromTo(stfadeInEls, {
      y: 20,
      scale: .25,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: .5,
      scale: 1,
      stagger: .25,
      scrollTrigger: {
        trigger: document.querySelector('.js-stagger-fadein-effect1'),
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  // });
}



const count = 25;
const particleClass = "js-particle"
const particleClassList = ["js-particle type1", "js-particle type2", "js-particle type3"]
const particleSizes = ["4.8rem", "3.6rem", "2.4rem", "2rem"]
const containers = document.querySelectorAll('.js-particle-content');
let tempelem;

if (containers) {
  containers.forEach((container)=>{
    const createCount = (isSmartPhone() ?container.getAttribute('data-count-sp') : container.getAttribute('data-count')) || count;
    const w = container.offsetWidth;
    const h = container.offsetHeight;
    for (let i = 0; i < createCount; i++) {
      tempelem = document.createElement('div');
      tempelem.className = particleClass
      container.appendChild(tempelem)
      let size = gsap.utils.random(particleSizes);
      gsap.set(tempelem, {
        x: gsap.utils.random(0, w),
        y: gsap.utils.random(0, (h )),
        className: gsap.utils.random(particleClassList),
        width: size,
        height: size,
      })
      anime(tempelem)
    }
    
    function anime(elm) {
      gsap.to(elm, gsap.utils.random(4, 10), {
        x: "+=40",
        y: "+=40",
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      })
      gsap.to(elm, gsap.utils.random(10, 20), {
        // opacity: 0,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      })  
    }
  })
}